<template>
  <transition name="fade" mode="out-in" appear>
    <div v-if="show" class="home">
      <video autoplay loop src="../assets/video3.webm" />
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return { show: true };
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Oswald&display=swap");
video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  transform: translateX(-50%) translateY(-50%);
  background-size: cover;
  transition: 1s opacity;
}
</style>
