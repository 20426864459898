<template>
  <div>
    <Slide right="true">
      <router-link to="/">Home</router-link>
      <router-link to="/music">Music</router-link>
      <router-link to="/services">Services</router-link>
      <router-link to="/contact">Contact</router-link>
    </Slide>
    <router-view />
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";

export default {
  components: {
    Slide,
  },
};
</script>

<style scoped>
a {
  font-weight: bold;
  color: #fff;
  margin: 10px;
  text-decoration: none;
}

a:hover {
  color: #b94242;
  transition: 0.2s ease-in-out;
}
</style>
